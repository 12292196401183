.app {
  text-align: center;
}

.welcome {
  height: 40vmin;
}

.app-header {
  background-color: #040404;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes welcome-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  [type="file"] + label:hover .welcome {
    animation: welcome-spin infinite 6s linear;
  }
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
}
  
[type="file"] + label:hover {
}
